.App {
  text-align: center;
  margin-bottom: 32pt;
}

.top-bar {
  width: 100%;
  background: #98c454;
  color: #1c233d;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-bar a {
  color: #1c233d;
  padding: 8pt;
}

.top-bar a.is-active {
  background: #ffffff;
}

.top-bar button {
  padding: 8pt;
}

.user-panel {
  margin: 8pt;
}

.menu-panel {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 8pt;
}

.section .section .menu-panel {
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
}

.section .section .menu {
  background: transparent;
}

.section .menu {
  background: #CCE2AA;
  width: 100%;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.section .menu a {
  color: #1c233d;
  padding: 8pt;
}

.section .menu a.is-active {
  background: #ffffff;
}

.section .menu a.is-active-warning {
  background: #FF654C;
}

.section .section .menu a.is-active {
  background: #e0e0e0;
}


.section .menu.white {
  background: #ffffff;
}

.table-container {
  overflow-x: auto;
  display: block;
  width: 95%;
  margin: auto;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  margin-top: 16pt;
  width: 100%;
}

table td, table th {
  padding: 10pt;
  border: 1px solid #000000;
}

table tr:nth-child(even) {
  background: #f0f0f0;
}

table tr:hover {
  background: #B1DD6C;
}

table textarea {
  width: 100%;
  height: 128pt;
}

table .cell-warning {
  background: #FFCA6F;
}

table .cell-error {
  background: #FF654C;
}

.qr-code {
  margin: 32pt;
}

.warning {
  background: #FF654C;
}

.notice {
  background: #b9c3ff;
}

.mode-service {
  background: #b9c3ff;
}

.mode-garage {
  background: #b8dbff;
}

.mode-live-inuse {
  background: #b8ffc7;
}

.device-map {
  margin: 16pt;
}

.paginator-container {
  margin-left: 2.5%;
  margin-top: 16pt;
}

.device-actions button {
  padding: 8pt;
  margin: 0 8pt 16pt 8pt;
}

.deviceIdMenuForm {
  padding: 8pt;
}

.deviceIdMenuForm input {
  font-size: 16px;
}

.deviceIdMenuForm input[type="text"] {
  width: 30pt;
}
